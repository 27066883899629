import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '7qJUyq0X1l9I1bFo5r5EvR',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - IRELAND',
        slides: [
          {
            id: '1W1oY8jOg09x6qKvieyyMC',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3CEmK3T1w3vufDyDlPMj5E/055049a76704e970df8b1db1aa3cebf8/GENERIC_HPBANNERS_iPhone13_Desktop_US.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3O0ziZ7py8WG1wojfUrtsJ/37bb5757ab6c96e1bbbde1d757b6be0a/GENERIC_HPBANNERS_iPhone13_Mobile_US.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IE - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '6yxcOyrOi1DEDwWwxpbAeW',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/35mSayC84ITt3CD59MltFS/a8929a59015eb834f6f151f7e52dcc71/Good_Deals_HP_Desktop_US.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4q1k3dhjoQESqH3tQHRlrK/ed8720db1280d6724fd5396ba0ea2981/Good_Deals_HP_Mobile_US.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IE - Good Deals',
            },
            link: {
              href: 'https://www.backmarket.ie/en-ie/e/good-deals',
            },
          },
          {
            id: '4Na1kjyxBh2PnusT4eYKrs',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4b09nJVeoFLzcWSZYMdkQM/975c175aa5302673f6ee3abe285a0921/Copy_of_VISION_Desktop_EN.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6OgPjRUiRwVW4HqKOtPsn7/8aa0c714ee52000642588165b8158e61/Copy_of_VISION_Mobile_EN.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'IE - Vision',
            },
            link: {
              href: 'https://www.backmarket.ie/en-ie/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '8mJfFij8sMPnz87cf2gXM',
      type: 'Categories',
      props: {
        title: 'Top Sellers',
        subtitle: 'Roll up, roll up!',
        categories: [
          {
            id: '1fPlj4dkTGT2l0uYmZmlNw',
            title: 'Smartphones',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3cnra6im5fWnEyRwS71Uvg/a5080ad4de4cc76d4bb4a0dbdb634c94/iphone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline:
              "Even smarter than when you get them brand new (don't you think?).",
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '2mla9iHILMG0pT1mkREroQ',
            title: 'AirPods',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/e8b2461fca9b19df9201c36d9cab637e/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods & Earphones',
            },
            tagline: 'Are you ready to become a pod person?',
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/airpods/5091ca6b-c36c-4d50-b644-fb3c5bdd5f16',
            },
          },
          {
            id: '6kIkPt90II6gXzZZp8woYs',
            title: 'MacBooks',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6MZVE5kh4OulMTCQlbTuT8/db4705439b97ee114ebe3a47ee785e32/MacBook_Pro_Touchbar.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Pro TouchBar',
            },
            tagline: 'Take advantage of our deals!',
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/macbook-english/50677fe5-b7d2-4f31-b8ed-824cb3bfd345',
            },
          },
          {
            id: '3d9L3X1IbfuI14Z9y51mh8',
            title: 'Apple Corner',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3fEBcng8EKMrWXZNfXsXWU/6d920e4b5d7b6d2fca8d4ff323e114ca/iPhone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline: 'Better than the Apple Store.',
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/apple/befd8e84-d318-48eb-88ef-362979a53183',
            },
          },
          {
            id: '3BvbyjNAQiqU9lawoBfYLh',
            title: 'Tablets',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3q7nQi6Q5LKO60xX5RPieH/ce68332f3989ed9166893ae754501094/ipad_pro_2019.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro',
            },
            tagline:
              'Up to 40% off. Something to help finance that Netflix subscription.',
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/tablets/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '3QsPGGGQ1lrfMEY2uv3oDa',
      type: 'Categories',
      props: {
        title: 'Other categories',
        subtitle: 'Nothing lost, everything gained, and all refurbished.',
        categories: [
          {
            id: '1Z6hp5tCb3jpbXmOj3yXXb',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c3phFqsPsCEl7dJiGDX5d/b9c268379f2a98835ba26195031a92d2/S10.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung s20',
            },
            tagline:
              "Even smarter than when you get them brand new (don't you think?)",
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/samsung-smartphone/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: '2gJcYOfhiC1y1xqYfORUBQ',
            title: 'Laptops',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/63528e98c7be1f68b61b28c0be4d2738/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'For work, for movies, for gaming... for less.',
            link: {
              href: '',
            },
          },
          {
            id: '4BbHvsvDz9R68R9LVwU2Vi',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2coRI1LOw46qbxuVTi3bJY/e51b366bef03cd1b1cc1a0b116060c50/AppleWatch6.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline: 'Time to upgrade that old Casio.',
            link: {
              href: 'https://www.backmarket.ie/en-ie/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '47QcBFoBpxVnOZjgeCvnmZ',
      type: 'PressCarousel',
      props: {
        technicalName: 'IE - Press Carousel',
        subtitle: 'They really love us!',
        title: 'They love us',
        articles: [
          {
            id: '5Mr3EvQXUCXG2px7tWF9I3',
            text: 'Should you be buying a second-hand smartphone?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6nEleXCKAMwP8Jr5xFzYkc/839eefd600f558cd40c5d57da0a42f9d/BBC_logo__1997-2021_.svg__1_.png',
              width: 1200,
              height: 342,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6nEleXCKAMwP8Jr5xFzYkc/839eefd600f558cd40c5d57da0a42f9d/BBC_logo__1997-2021_.svg__1_.png',
              widthMobile: 1200,
              heightMobile: 342,
              alt: 'BBC',
            },
            link: {
              href: 'https://www.bbc.com/news/business-54160851',
            },
            publicationDate: '10/2020',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4DTL2QTXBErPzNImxncXlw',
      type: 'Faq',
      props: {
        title: 'What else?',
        subtitle: '4 questions people always ask',
        questions: [
          {
            id: '5s8UKrRggniSCRa0M2fNHx',
            title: 'How do I know my device won’t conk out in a month?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'From a pre-list screening process for all sellers to “mystery orders” placed by Back Market technical staff (under secret code names 🤫), we give 110% in making sure the tech we offer you is what we promise. Plus, every device comes with 30 days to change your mind and a 1-year warranty.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '5khFPEpw7RzOlXpHISqkUe',
            title: 'What’s the difference between renewed and new?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "On the outside, a renewed smartphone looks (and works) like new. But it’s what's on the inside that really counts. Renewed tech — smartphones, tablets, laptops, and computers — have way less environmental impact than brand new.\n\n",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6MyPqQvjB1rgoPw4W3Ty1q',
            title: 'Wait a hot sec…what else do you guys sell?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'You can cut the planet a break in more ways than one, and when it comes to tech, we’re way more than a pretty face smartphone. From gaming systems to curling irons, or even baby monitors and espresso machines (hello, new parentals), we most likely have the goods.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: 'N1otXoYyeHFKCFTBJacDn',
            title:
              'Was the T-800 in Terminator 2: Judgment Day (1991) technically “refurbished”?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'We’d certainly need to put Skynet through our certification process before we give a definitive yes or no answer, but we have a hunch that yes, it’s an “I’ll be back” scenario.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.ie/en-ie/help',
          },
          label: 'Find answers',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'Your Refurbished (Super) Marketplace',
    description:
      'Buy the best refurbished smartphones, laptops and tech on Back Market, at up to 70% cheaper than buying new! Free delivery - 12 month warranty - secure payments - 30 day returns.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/b5a9675d7d2f7a87316c73f0d2788170/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Your Refurbished (Super) Marketplace',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
